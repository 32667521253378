<template>
  <b-modal
    :id="id"
    :title="title"
    :size="size"
    @show="onShow"
    @ok="onSubmit"
  >
    <slot />
    <!-- Available Objects -->
    <b-row>
      <b-col>
        <label :for="`${id}-left`">
          <b-badge class="bg-dark">{{ leftOpts.length }}</b-badge> :
          Available {{ leftLabel }}
        </label>
        <b-form-select
          :id="`${id}-left`"
          v-model="selected_left"
          :options="leftOpts"
          multiple
          :select-size="6"
        />
      </b-col>
    </b-row>

    <!-- Association and Un-association buttons -->
    <b-row class="mx-auto">
      <b-col>
        <b-button-group class="w-100">
          <!-- Remove Association -->
          <b-button
            variant="flat-danger"
            @click="associateEntity"
          >
            <feather-icon icon="ArrowUpIcon" size="24" />
          </b-button>

          <!-- Add Association -->
          <b-button
            variant="flat-success"
            @click="removeAssociatedEntity"
          >
            <feather-icon icon="ArrowDownIcon" size="24" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- Associated Objects -->
    <b-row class="mb-2">
      <b-col>
        <label :for="`${id}-right`">
          <b-badge class="bg-primary">{{ right_opts_v.length }}</b-badge> :
          Associated {{ rightLabel }}
        </label>
        <b-form-select
          :id="`${id}-right`"
          v-model="selected_right"
          :options="right_opts_v"
          multiple
          :select-size="6"
        />
      </b-col>
    </b-row>

    <!-- Action buttons -->
    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Close
      </b-button>
      <b-button variant="success" @click="onSubmit">
        Save associations
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: [String],
      required: true,
    },
    title: {
      type: [String],
      default: null,
    },
    leftOpts: {
      type: [Array],
      default: null,
    },
    leftLabel: {
      type: [String],
      default: null,
    },
    rightOpts: {
      type: [Array],
      default: null,
    },
    rightLabel: {
      type: [String],
      default: null,
    },
    size: {
      type: [String],
      default: 'lg',
    },
    objType: {
      type: [String],
      default: null,
    },

  },
  data: () => ({
    newEntityObject: '',
    selected_left: [],
    selected_right: [],
    left_opts_v: [],
    right_opts_v: [],
  }),
  watch: {
    rightOpts(newVal) {
      this.right_opts_v = [...newVal]
    },
  },
  methods: {
    onShow() {
      this.$emit('onShow')
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$emit('ok', evt, [...this.right_opts_v])
      this.$bvModal.hide(this.id)
    },
    getItem(opts, value) {
      return opts.find(x => ((typeof (x) === 'string') ? x === value : x.value === value))
    },
    getValue(v) {
      return (typeof (v) === 'string') ? v : v.value
    },
    addNew() {
      this.right_opts_v.push({ value: { name: this.newEntityObject }, text: this.newEntityObject })
    },
    associateEntity() {
      this.right_opts_v = this.right_opts_v.filter(n => !this.selected_right.includes(this.getValue(n)))
      this.selected_right = []
    },
    removeAssociatedEntity() {
      this.selected_left.forEach(e => {
        if (e !== null) {
          if (!this.right_opts_v.includes(this.getItem(this.leftOpts, e))) {
            this.right_opts_v.push(this.getItem(this.leftOpts, e))
          }
        }
      })
      this.selected_left = []
    },
  },
}
</script>
