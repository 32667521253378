<template>
  <div>
    <b-modal
      id="add-idea-modal"
      title="Create Idea"
      size="xl"
      lazy
      no-close-on-backdrop
      @show="fetchData"
    >
      <b-row>
        <b-col>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scroll-area-idea-entities"
          >
            <!-- Idea Source -->
            <b-row class="mb-2">
              <b-col>
                <b-form-group label="Source" label-for="source-input">
                  <b-form-input id="source-input" v-model="sub_obj.source" list="source-list" />

                  <datalist id="source-list">
                    <option
                      v-for="source in sources"
                      :key="source.name"
                    >
                      {{ source.name }}
                    </option>
                  </datalist>
                </b-form-group>
              </b-col>
              <b-col>
                <label for="type-input">Idea type</label>
                <b-form-select id="type-input" v-model="sub_obj.type" :options="ideaTypes" />
              </b-col>
            </b-row>

            <!-- Idea Description -->
            <b-row>
              <b-col>
                <tip-tap-editor
                  ref="descEditor"
                  v-model="sub_obj.description"
                  placeholder="Describe the Idea..."
                  min-height="8"
                  max-height="8"
                />
                <!-- FIXME tip-tap-editor - min-height: 8rem -->
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </b-col>

        <!--<b-col>-->
        <!--  &lt;!&ndash; Linked Entities Header &ndash;&gt;-->
        <!--  <h3>-->
        <!--    Linked Entities-->
        <!--  </h3>-->
        <!--  <hr class="mb-2">-->

        <!--  <vue-perfect-scrollbar-->
        <!--    :settings="perfectScrollbarSettings"-->
        <!--    class="scroll-area-idea-entities"-->
        <!--  >-->

        <!--    &lt;!&ndash; Stakeholders &ndash;&gt;-->
        <!--    <b-row class="mb-2">-->
        <!--      <b-col>-->
        <!--        <list-group-entity-->
        <!--          label="Stakeholders"-->
        <!--          :entity-array="sub_obj.stakeholders"-->
        <!--          modal="associator-generic-stakeholders"-->
        <!--        />-->
        <!--      </b-col>-->
        <!--    </b-row>-->

        <!--    &lt;!&ndash; Objectives &ndash;&gt;-->
        <!--    <b-row class="mb-2">-->
        <!--      <b-col>-->
        <!--        <list-group-entity-->
        <!--          label="Objectives"-->
        <!--          :entity-array="sub_obj.objectives"-->
        <!--          modal="associator-generic-objectives"-->
        <!--        />-->
        <!--      </b-col>-->
        <!--    </b-row>-->

        <!--    &lt;!&ndash; Features / Functions &ndash;&gt;-->
        <!--    <b-row class="mb-2">-->
        <!--      <b-col>-->
        <!--        <list-group-entity-->
        <!--          label="Functions"-->
        <!--          :entity-array="sub_obj.functions"-->
        <!--          modal="associator-generic-functions"-->
        <!--        />-->
        <!--      </b-col>-->
        <!--    </b-row>-->

        <!--    &lt;!&ndash; Resources / Entities / Objects &ndash;&gt;-->
        <!--    &lt;!&ndash;<b-row class="mb-2">&ndash;&gt;-->
        <!--    &lt;!&ndash;  <b-col>&ndash;&gt;-->
        <!--    &lt;!&ndash;    <list-group-entity&ndash;&gt;-->
        <!--    &lt;!&ndash;      label="Resources"&ndash;&gt;-->
        <!--    &lt;!&ndash;      :entity-array="sub_obj.objects"&ndash;&gt;-->
        <!--    &lt;!&ndash;      modal="associate-idea-rsc-modal-a"&ndash;&gt;-->
        <!--    &lt;!&ndash;    />&ndash;&gt;-->
        <!--    &lt;!&ndash;  </b-col>&ndash;&gt;-->
        <!--    &lt;!&ndash;</b-row>&ndash;&gt;-->

        <!--    &lt;!&ndash; Performers &ndash;&gt;-->
        <!--    &lt;!&ndash;<b-row class="mb-2">&ndash;&gt;-->
        <!--    &lt;!&ndash;  <b-col>&ndash;&gt;-->
        <!--    &lt;!&ndash;    <list-group-entity&ndash;&gt;-->
        <!--    &lt;!&ndash;      label="Performers"&ndash;&gt;-->
        <!--    &lt;!&ndash;      :entity-array="sub_obj.performers"&ndash;&gt;-->
        <!--    &lt;!&ndash;      modal="associate-idea-perf-modal"&ndash;&gt;-->
        <!--    &lt;!&ndash;    />&ndash;&gt;-->
        <!--    &lt;!&ndash;  </b-col>&ndash;&gt;-->
        <!--    &lt;!&ndash;</b-row>&ndash;&gt;-->
        <!--  </vue-perfect-scrollbar>-->
        <!--</b-col>-->
      </b-row>

      <template v-slot:modal-footer="{ parse, add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Discard
        </b-button>

        <b-button variant="success" :disabled="loading_status_add" @click="addIdeaNoParse">
          <span v-if="loading_status_add">
            <b-spinner small type="grow" />
            Creating...
          </span>
          <span v-else>
            Create
          </span>
        </b-button>

        <b-button variant="primary" :disabled="loading_status_add" @click="addIdeaParse">
          <span v-if="loading_status_add">
            <b-spinner small type="grow" />
            Parsing...
          </span>
          <span v-else>
            Parse and Create
          </span>
        </b-button>
      </template>
    </b-modal>

    <!--<Associator-->
    <!--  id="associate-idea-sh-modal-a"-->
    <!--  title="Link Idea with Stakeholders"-->
    <!--  left-label="All Stakeholders"-->
    <!--  :left-opts="stakeholders.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Stakeholders"-->
    <!--  :right-opts="sub_obj.stakeholders.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaStakeholders"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-idea-obj-modal-a"-->
    <!--  title="Link Idea with Objective/Outcome"-->
    <!--  left-label="All Objectives"-->
    <!--  :left-opts="objectives.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Objectives"-->
    <!--  :right-opts="sub_obj.objectives.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaObjectives"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-idea-fn-modal-a"-->
    <!--  title="Link Idea with Features/Functions"-->
    <!--  left-label="All Functions"-->
    <!--  :left-opts="functions.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Functions"-->
    <!--  :right-opts="sub_obj.functions.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaFunctions"-->
    <!--/>-->

    <!-- Stakeholders -->
    <associator-generic
      name="Stakeholders-Add"
      :associated-items="sub_obj.stakeholders.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="stakeholders.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaStakeholders"
    />

    <!-- Objectives -->
    <associator-generic
      name="Objectives-Add"
      :associated-items="sub_obj.objectives.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="objectives.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaObjectives"
    />

    <!-- Functions -->
    <associator-generic
      name="Functions-Add"
      :associated-items="sub_obj.functions.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="functions.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaFunctions"
    />
  </div>
</template>

<script>
import Associator from '@/components/Forms/M_Associator.vue'
import { mapGetters, mapState } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'

export default {
  components: {
    TipTapEditor,
    VuePerfectScrollbar,
    AssociatorGeneric,
  },
  data: () => ({
    sub_obj: {
      description: '',
      source: '',
      type: '',
      performers: [],
      functions: [],
      stakeholders: [],
      objectives: [],
      objects: [],
      parse: false,
    },
    loading_status_add: false,
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    },
    dataLoaded: false,
  }),
  computed: {
    ...mapState({
      ideas: state => state.ideas.ideas,
      sources: state => state.ideas.sources,
      stakeholders: state => state.canvas.stakeholders,
      objectives: state => state.canvas.objectives,
      functions: state => state.domainModel.functions,
    }),
    ...mapGetters({
      ideaTypes: 'constants/ideaTypes',
      stereotypes: 'constants/stereotypes',
    }),
  },
  methods: {
    setDefaults() {
      this.sub_obj.type = this.$store.state.constants.defaultIdeaType.id
    },
    fetchData() {
      this.setDefaults()
      if (!this.dataLoaded) {
        this.$store.dispatch('canvas/fetchObjectives')
        this.$store.dispatch('canvas/fetchStakeholders')
        this.$store.dispatch('ideas/syncSources')
        this.dataLoaded = true
      }
    },
    addIdeaParse() {
      this.loading_status_add = true
      this.sub_obj.parse = true
      console.debug('Adding and Parsing: ', this.sub_obj)
      this.$store.dispatch('ideas/createIdea', this.sub_obj)
        .then(() => {
          this.loading_status_add = false
          this.sub_obj.parse = false
          this.$bvModal.hide('add-idea-modal')
          this.sub_obj.description = ''
          this.sub_obj.source = ''
          this.$store.dispatch('canvas/fetchObjectives')
          this.$store.dispatch('canvas/fetchStakeholders')
          this.$store.dispatch('ideas/syncSources')
        })
    },
    addIdeaNoParse() {
      this.loading_status_add = true
      console.debug('Adding: ', this.sub_obj)
      this.$store.dispatch('ideas/createIdea', this.sub_obj)
        .then(() => {
          this.loading_status_add = false
          this.$bvModal.hide('add-idea-modal')
          this.sub_obj.description = ''
          this.sub_obj.source = ''
          this.$store.dispatch('canvas/fetchObjectives')
          this.$store.dispatch('canvas/fetchStakeholders')
          this.$store.dispatch('ideas/syncSources')
        })
    },
    linkIdeaObjectives(objs) {
      console.debug('Linking: ', objs)
      this.sub_obj.objectives = objs.map(x => ({
        id: x.value.id,
        name: x.text,
      }))

      // this.$bvModal.hide('associate-idea-obj-modal-a')
      console.debug('Objectives linked: ', this.sub_obj.objectives)
    },
    linkIdeaStakeholders(shs) {
      console.debug('Linking stakeholders...', shs)
      this.sub_obj.stakeholders = shs.map(x => ({
        id: x.value.id,
        name: x.text,
      }))

      // this.$bvModal.hide('associate-idea-sh-modal-a')
      console.debug('Stakeholders linked: ', this.sub_obj.stakeholders)
    },
    linkIdeaFunctions(func) {
      this.sub_obj.functions = func.map(x => ({
        id: x.value.id,
        name: x.text,
      }))
      // this.$bvModal.hide('associate-idea-fn-modal-a')
    },
  },
}
</script>

<style lang="scss">
#add-idea-modal {
  // b-modal size must be "xl" for this to apply.
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}
</style>
