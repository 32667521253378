var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"add-idea-modal","title":"Create Idea","size":"xl","lazy":"","no-close-on-backdrop":""},on:{"show":_vm.fetchData},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var parse = ref.parse;
var add = ref.add;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Discard ")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading_status_add},on:{"click":_vm.addIdeaNoParse}},[(_vm.loading_status_add)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Creating... ")],1):_c('span',[_vm._v(" Create ")])]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading_status_add},on:{"click":_vm.addIdeaParse}},[(_vm.loading_status_add)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Parsing... ")],1):_c('span',[_vm._v(" Parse and Create ")])])]}}])},[_c('b-row',[_c('b-col',[_c('vue-perfect-scrollbar',{staticClass:"scroll-area-idea-entities",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Source","label-for":"source-input"}},[_c('b-form-input',{attrs:{"id":"source-input","list":"source-list"},model:{value:(_vm.sub_obj.source),callback:function ($$v) {_vm.$set(_vm.sub_obj, "source", $$v)},expression:"sub_obj.source"}}),_c('datalist',{attrs:{"id":"source-list"}},_vm._l((_vm.sources),function(source){return _c('option',{key:source.name},[_vm._v(" "+_vm._s(source.name)+" ")])}),0)],1)],1),_c('b-col',[_c('label',{attrs:{"for":"type-input"}},[_vm._v("Idea type")]),_c('b-form-select',{attrs:{"id":"type-input","options":_vm.ideaTypes},model:{value:(_vm.sub_obj.type),callback:function ($$v) {_vm.$set(_vm.sub_obj, "type", $$v)},expression:"sub_obj.type"}})],1)],1),_c('b-row',[_c('b-col',[_c('tip-tap-editor',{ref:"descEditor",attrs:{"placeholder":"Describe the Idea...","min-height":"8","max-height":"8"},model:{value:(_vm.sub_obj.description),callback:function ($$v) {_vm.$set(_vm.sub_obj, "description", $$v)},expression:"sub_obj.description"}})],1)],1)],1)],1)],1)],1),_c('associator-generic',{attrs:{"name":"Stakeholders-Add","associated-items":_vm.sub_obj.stakeholders.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }
    }),"all-items":_vm.stakeholders.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }
    })},on:{"associated":_vm.linkIdeaStakeholders}}),_c('associator-generic',{attrs:{"name":"Objectives-Add","associated-items":_vm.sub_obj.objectives.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }
    }),"all-items":_vm.objectives.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }
    })},on:{"associated":_vm.linkIdeaObjectives}}),_c('associator-generic',{attrs:{"name":"Functions-Add","associated-items":_vm.sub_obj.functions.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }
    }),"all-items":_vm.functions.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.name,
        },
        text: item.name,
      }
    })},on:{"associated":_vm.linkIdeaFunctions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }