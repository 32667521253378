<template>
  <b-container>
    <b-row class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-success"
        class="btn-icon"
        @click="$bvModal.show('add-idea-modal')"
      >
        <feather-icon icon="Edit3Icon" />
        Compose an Idea
      </b-button>

      <b-button
        v-if="!loading"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-secondary"
        class="btn-icon ml-50"
        @click="fetchIdeas"
      >
        <feather-icon icon="RefreshCcwIcon" />
        Refresh
      </b-button>
      <b-button
        v-else
        variant="outline-secondary"
        disabled
        class="btn-icon ml-1"
      >
        <b-spinner small />
        Loading
      </b-button>
    </b-row>

    <b-row>
      <b-col>
        <b-overlay
          v-if="ideas.length > 0"
          :show="loading"
          variant="light"
          rounded="xl"
        >
          <b-card-group columns>
            <div v-for="idea in ideas" :key="idea.id">
              <b-card
                no-body
                class="ideaCard"
                @click="edit_idea(idea.id)"
              >
                <b-card-header>
                  <div class="w-100">
                    <b-card-text class="font-small-2">
                      <div class="d-inline">
                        Created
                        <abbr :title="idea.created">
                          {{ idea.created | diffForHumans }}
                        </abbr>
                      </div>
                      <div class="d-inline float-right">
                        <abbr :title="ideaType(idea).description" class="text-primary font-weight-bolder font-small-2">
                          {{ ideaType(idea).label }}
                        </abbr>
                      </div>
                    </b-card-text>
                  </div>
                  <!--<b-dropdown-->
                  <!--  variant="link"-->
                  <!--  class="chart-dropdown"-->
                  <!--  toggle-class="p-0"-->
                  <!--&gt;-->
                  <!--  <template #button-content>-->
                  <!--    <feather-icon-->
                  <!--      icon="MoreHorizontalIcon"-->
                  <!--      size="18"-->
                  <!--      class="text-body cursor-pointer"-->
                  <!--    />-->
                  <!--  </template>-->
                  <!--  <b-dropdown-item @click="delete_idea(idea.id)">-->
                  <!--    <feather-icon icon="TrashIcon" class="text-danger mr-50 mb-25" />-->
                  <!--    Delete-->
                  <!--  </b-dropdown-item>-->
                  <!--</b-dropdown>-->
                </b-card-header>

                <b-card-body>
                  <div
                    v-if="idea.description !== ''"
                    class="overflow-auto"
                    v-sanitized-html="idea.description"
                  />
                  <span v-else class="text-muted font-small-3">(empty content)</span>
                </b-card-body>
              </b-card>
            </div>
          </b-card-group>
        </b-overlay>
        <div v-else class="w-100 text-center">
          <span
            class="font-medium-2"
          >
            No items found
          </span>
        </div>
      </b-col>
    </b-row>

    <AddIdeaModal />
    <EditIdeaModal />
    <DeleteIdeaModal />
  </b-container>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'

import AddIdeaModal from '@/components/Ideas/Modals/M_AddIdeaModal.vue'
import EditIdeaModal from '@/components/Ideas/Modals/M_EditIdeaModal.vue'
import DeleteIdeaModal from '@/components/Ideas/Modals/M_DeleteIdeaModal.vue'

export default {
  name: 'Ideas',
  directives: {
    Ripple,
  },
  components: {
    AddIdeaModal,
    EditIdeaModal,
    DeleteIdeaModal,
    BOverlay,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      ideas: state => state.ideas.ideas,
      selectedIdea: state => state.ideas.selectedIdea,
    }),
  },
  mounted() {
    this.fetchIdeas()
  },
  methods: {
    fetchIdeas() {
      this.loading = true
      this.$store.dispatch('ideas/fetchIdeas')
        .then(() => {
          setTimeout(() => { this.loading = false }, 300)
        })
    },
    ideaType(idea) {
      if (idea.type.toLowerCase() === 'hmw') {
        return { label: 'How might we?', description: 'An idea that captures an information to be expanded upon.' }
      }
      if (idea.type.toLowerCase() === 'need') {
        return { label: 'Need to have', description: 'An idea that needs to be implemented into the model.' }
      }
      if (idea.type.toLowerCase() === 'note') {
        return { label: 'Note', description: 'An idea that is a note to capture information.' }
      }
      return { label: 'Unknown', description: 'Invalid idea type' }
    },
    edit_idea(id) {
      this.$store.dispatch('ideas/selectIdea', id)
      this.$bvModal.show('edit-idea-modal')
    },
    delete_idea(id) {
      this.$store.dispatch('ideas/selectIdea', id)
      this.$bvModal.show('delete-idea-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables';
@import '~@core/scss/base/components/variables-dark';

.ideaCard {
  position: static;
  cursor: pointer;
}

.ideaCard:hover {
  background: rgba(206, 207, 212, 0.2);
}

body.dark-layout {
  .ideaCard:hover {
    background: rgba(40, 48, 70, 0.6);
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

body.dark-layout {
  // Loading panel background colour
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
