<template>
  <div>
    <b-modal
      id="edit-idea-modal"
      title="Update Idea"
      size="xl"
      lazy
      no-close-on-backdrop
      @show="fetchData"
    >
      <b-row>
        <b-col>
          <!-- Linked Entities Header -->
          <h3>
            Idea Description
          </h3>
          <hr class="mb-2">

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scroll-area-idea-entities"
          >
            <!-- Idea Source -->
            <b-row class="mb-2">
              <b-col>
                <b-form-group label="Source" label-for="source-input">
                  <b-form-input id="source-input" v-model="sub_obj.source" list="source-list" />

                  <datalist id="source-list">
                    <option
                      v-for="source in sources"
                      :key="source.name"
                    >
                      {{ source.name }}
                    </option>
                  </datalist>
                </b-form-group>
              </b-col>
              <b-col>
                <label for="type-input">Idea Type:</label>
                <b-form-select id="type-input" v-model="sub_obj.type" :options="ideaTypes" />
              </b-col>
            </b-row>

            <!-- Idea Description -->
            <b-row>
              <b-col>
                <tip-tap-editor
                  ref="descEditor"
                  v-model="sub_obj.description"
                  placeholder="Describe the Idea..."
                />
                <!-- FIXME tip-tap-editor - min-height: 22vh -->
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </b-col>

        <b-col v-if="dataLoaded">
          <!-- Linked Entities Header -->
          <h3>
            Linked Entities
          </h3>
          <hr class="mb-2">

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scroll-area-idea-entities"
          >

            <!-- Stakeholders -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Stakeholders"
                  :entity-array="sub_obj.stakeholders"
                  modal="associator-generic-stakeholders"
                />
              </b-col>
            </b-row>

            <!-- Objectives -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Objectives"
                  :entity-array="sub_obj.objectives"
                  modal="associator-generic-objectives"
                />
              </b-col>
            </b-row>

            <!-- Features / Functions -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Functions"
                  :entity-array="sub_obj.functions"
                  modal="associator-generic-functions"
                />
              </b-col>
            </b-row>

            <!-- Resources / Entities / Objects -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Resources"
                  :entity-array="sub_obj.objects"
                  modal="associator-generic-entities"
                />
              </b-col>
            </b-row>

            <!-- Performers -->
            <b-row class="mb-2">
              <b-col>
                <list-group-entity
                  label="Performers"
                  :entity-array="sub_obj.performers"
                  modal="associator-generic-performers"
                />
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </b-col>
        <b-col v-else>
          <h1 class="text-center text-warning w-100">
            <b-spinner />
          </h1>
        </b-col>
      </b-row>

      <!-- Modal Buttons -->
      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="success" :disabled="loading_status_add" @click="editIdeaP">
          <span v-if="loading_status_add">
            <b-spinner small type="grow" />
            Parsing...
          </span>

          <span v-else>
            Parse and Save
          </span>

        </b-button>

        <b-button variant="outline-success" :disabled="loading_status_add" @click="editIdea">
          <span v-if="loading_status_add">
            <b-spinner small type="grow" />
            Saving...
          </span>

          <span v-else>
            Save
          </span>

        </b-button>
        <b-button variant="outline-dark" @click="cancel()">
          Cancel
        </b-button>
        <b-button v-b-tooltip.hover.top="'Idea History'" variant="outline-warning" class="btn-icon" @click="openHistory()">
          <font-awesome-icon :icon="['fas', 'history']" />
        </b-button>
        <b-button id="idea-delete-button" variant="out~line-danger" class="btn-icon" @click="deleteIdea()">
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>
    </b-modal>

    <!--<Associator-->
    <!--  id="associate-idea-sh-modal"-->
    <!--  title="Link Idea with Stakeholders"-->
    <!--  left-label="All Stakeholders"-->
    <!--  :left-opts="stakeholders.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Stakeholders"-->
    <!--  :right-opts="sub_obj.stakeholders.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaStakeholders"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-idea-obj-modal"-->
    <!--  title="Link Idea with Objective/Outcome"-->
    <!--  left-label="All Objectives"-->
    <!--  :left-opts="objectives.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Objectives"-->
    <!--  :right-opts="sub_obj.objectives.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaObjectives"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-idea-fn-modal"-->
    <!--  title="Link Idea with Features/Functions"-->
    <!--  left-label="All Functions"-->
    <!--  :left-opts="functions.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Functions"-->
    <!--  :right-opts="sub_obj.functions.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaFunctions"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-idea-rsc-modal"-->
    <!--  title="Link Idea with Entities/Objects/Resources"-->
    <!--  left-label="All Entities"-->
    <!--  :left-opts="entities.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Entities"-->
    <!--  :right-opts="sub_obj.objects.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaEntities"-->
    <!--/>-->
    <!--<Associator-->
    <!--  id="associate-idea-perf-modal"-->
    <!--  title="Link Idea with Performers"-->
    <!--  left-label="All Performers"-->
    <!--  :left-opts="performers.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  right-label="Linked Performers"-->
    <!--  :right-opts="sub_obj.performers.map(x=>{return{value:x.id,text:x.name}})"-->
    <!--  @ok="linkIdeaPerformers"-->
    <!--/>-->

    <!-- Stakeholders -->
    <associator-generic
      name="Stakeholders"
      :associated-items="sub_obj.stakeholders.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="stakeholders.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaStakeholders"
    />

    <!-- Objectives -->
    <associator-generic
      name="Objectives"
      :associated-items="sub_obj.objectives.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="objectives.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaObjectives"
    />

    <!-- Functions -->
    <associator-generic
      name="Functions"
      :associated-items="sub_obj.functions.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="functions.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaFunctions"
    />

    <!-- Entities -->
    <associator-generic
      name="Entities"
      :associated-items="sub_obj.objects.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="entities.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaEntities"
    />

    <!-- Performers -->
    <associator-generic
      name="Performers"
      :associated-items="sub_obj.performers.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      :all-items="performers.map(item => {
        return {
          value: {
            id: item.id,
            toSortBy: item.name,
          },
          text: item.name,
        }
      })"
      @associated="linkIdeaPerformers"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { BButton, VBModal } from 'bootstrap-vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AssociatorGeneric from '@/components/Generic/Associators/AssociatorGeneric.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
    BButton,
    AssociatorGeneric,
    ListGroupEntity,
    VuePerfectScrollbar,
  },
  directives: {
    'b-modal': VBModal,
  },
  data: () => ({
    sub_obj: {
      id: '',
      description: '',
      updated: '',
      source: '',
      type: '',
      performers: [],
      functions: [],
      objectives: [],
      stakeholders: [],
      objects: [],
      parse: false,
    },
    dataLoaded: false,
    loading_status_add: false,
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollX: true,
    },
  }),
  computed: {
    ...mapState({
      selectedIdea: state => state.ideas.selectedIdea,
      sources: state => state.ideas.sources,
      stakeholders: state => state.canvas.stakeholders,
      objectives: state => state.canvas.objectives,
      functions: state => state.domainModel.functions,
      entities: state => state.domainModel.entities,
      performers: state => state.domainModel.performers,
    }),
    ...mapGetters({
      ideaTypes: 'constants/ideaTypes',
    }),
  },
  watch: {
    default(newVal) {
      this.value = newVal
    },
    selectedIdea(newVal) {
      const t = this.sub_obj
      t.id = newVal.id
      t.description = newVal.description
      t.updated = newVal.updated
      t.source = newVal.source
      t.type = newVal.type
      t.objectives = newVal.objectives
      t.performers = newVal.performers
      t.stakeholders = newVal.stakeholders
      t.functions = newVal.functions
      t.objects = newVal.objects
    },
  },
  methods: {
    fetchData() {
      if (!this.dataLoaded) {
        Promise.all([
          this.$store.dispatch('canvas/fetchObjectives'),
          this.$store.dispatch('canvas/fetchStakeholders'),
          this.$store.dispatch('ideas/syncSources'),
          this.$store.dispatch('domainModel/getComponents'),
        ]).then(() => {
          this.dataLoaded = true
        })
      }
    },
    editIdeaP() {
      this.loading_status_add = true
      this.sub_obj.parse = true
      this.$store.dispatch('ideas/updateIdea', this.sub_obj)
        .then(() => {
          this.loading_status_add = false
          this.sub_obj.parse = false
          this.$bvModal.hide('edit-idea-modal')
          this.$store.dispatch('canvas/fetchObjectives')
          this.$store.dispatch('canvas/fetchStakeholders')
          this.$store.dispatch('ideas/syncSources')
        })
    },
    editIdea() {
      this.loading_status_add = true
      this.$store.dispatch('ideas/updateIdea', this.sub_obj)
        .then(() => {
          this.loading_status_add = false
          this.$bvModal.hide('edit-idea-modal')
          this.$store.dispatch('canvas/fetchObjectives')
          this.$store.dispatch('canvas/fetchStakeholders')
          this.$store.dispatch('ideas/syncSources')
        })
    },
    deleteIdea() {
      this.$store.dispatch('ideas/selectIdea', this.sub_obj.id)
      this.$bvModal.show('delete-idea-modal')
      this.$bvModal.hide('edit-idea-modal')
    },
    openHistory() {
      this.$bvModal.hide('edit-idea-modal')
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.sub_obj.id,
          },
        },
      )
    },
    linkIdeaObjectives(objs) {
      const temp = this.findItemById(objs, this.objectives)

      this.sub_obj.objectives = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
    },
    linkIdeaStakeholders(shs) {
      const temp = this.findItemById(shs, this.stakeholders)

      this.sub_obj.stakeholders = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
    },
    linkIdeaFunctions(func) {
      const temp = this.findItemById(func, this.functions)

      this.sub_obj.functions = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
    },
    linkIdeaEntities(ents) {
      const temp = this.findItemById(ents, this.entities)

      this.sub_obj.objects = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
    },
    linkIdeaPerformers(perf) {
      const temp = this.findItemById(perf, this.performers)
      console.log(temp)
      this.sub_obj.performers = temp.map(x => ({
        id: x.id,
        name: x.name,
      }))
    },
    findItemById(toAssociate, allItems) {
      // Associators generic spit out arrays of just IDs
      // all link methods need ID and Name
      // this method will find the full object by ID from the all items array
      // Eg. const temp = this.findItemById(array, this.array)
      function sortFunction(value) {
        return allItems.find(item => (item.id === value))
      }

      return toAssociate.map(sortFunction)
    },
  },
}
</script>

<style lang="scss">
#edit-idea-modal {
  // b-modal size must be "xl" for this to apply.
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.scroll-area-idea-entities {
  max-height: 60vh;
}
</style>
