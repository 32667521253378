<template>
  <b-modal
    id="delete-idea-modal"
    title="Delete Idea"
    size="lg"
    centered
    lazy
    no-close-on-backdrop
    @ok="deleteSelectedIdea"
  >
    <span class="font-medium-2 text-primary font-weight-bold">
      Are you sure you wish to delete the below idea?
    </span>
    <hr class="mb-2">
    <div
      v-sanitized-html="`${selectedIdea.description}`"
    />
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-dark" @click="cancel()">
        No, keep the Idea
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, Delete the Idea
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedIdea: state => state.ideas.selectedIdea,
    }),
  },
  methods: {
    deleteSelectedIdea(evt) {
      evt.preventDefault()
      this.$store.dispatch('ideas/deleteIdea', this.selectedIdea.id).then(() => {
        this.$bvModal.hide('delete-idea-modal')
      })
    },

  },
}
</script>
